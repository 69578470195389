<template>
    <BaseModal
        data-width="medium"
        :is-visible="modalStore.activeModals['add-employee'] === true"
    >
        <template #title>Medewerker uitnodigen voor Mijn TLN</template>

        <template #default>
            <AddEmployeeForm
                :organization-id="modalData.organizationId"
                :employee="modalData.employee"
                :success-redirect-path="successRedirectPath"
                @dismiss="dismissModal"
            />
        </template>
    </BaseModal>
</template>

<script setup>
import { useModalStore } from '~/store/modal';
import { useNavigationStore } from '~/store/navigation';

const modalStore = useModalStore();

const modalData = computed(() => {
    return modalStore.data['add-employee'];
});

const mijnTlnGlobalData = computed(() => {
    return useNavigationStore().mijnTln;
});

const successRedirectPath = computed(() => {
    const successEntry = toValue(mijnTlnGlobalData).successEntryAddEmployee?.[0];

    if (!successEntry) {
        return;
    }

    return `/${successEntry.uri}`;
});

const dismissModal = () => {
    modalStore.deactivateAllModals();
};
</script>

<style lang="less"></style>
